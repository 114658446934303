@import '../../../sass/variables';

.wrapper {
  border-left: 1px solid rgba($color-white, 0.3);
  display: inline;
  padding-left: 0.5rem;
}

.icon {
  font-size: 0.8rem;
  margin-right: 0.25rem;
}
