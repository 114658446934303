.backdrop {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
  inset: 0;
  position: fixed;
  z-index: 999;
}

.dialog {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: var(--modal-background-color);
  border-radius: var(--spacing-extra-small);
  box-shadow: 0 3px 10px #000;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  left: 50%;
  max-height: calc(100vh - 56px);
  outline: 0;
  overflow: hidden;
  padding: 1rem;
  position: fixed;
  top: 28px;
  transform: translateX(-50%);
  width: 600px;
  z-index: 999;
}

.closeDialog {
  appearance: none;
  background: none;
  border: 0;
  color: var(--color-white);
}

.header {
  border-bottom: var(--border);
  display: flex;
  margin-bottom: 1rem;
  padding-bottom: 1rem;

  h1 {
    flex: 1;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0;
  }
}

.body {
  overflow: auto;
}

.dialog footer,
.footer {
  border-top: var(--border);
  margin-top: var(--spacing-normal);
  padding-top: var(--spacing-normal);
  text-align: center;
}
