.fc-muted {
  color: rgba(
    var(--color-white-r),
    var(--color-white-g),
    var(--color-white-b),
    0.5
  );
}

.fs-small {
  font-size: 0.8rem;
}

.fw-bold {
  font-weight: bold;
}

.fw-normal {
  font-weight: normal;
}

.ta-center {
  text-align: center;
}
