.container {
  padding: 0 0.25rem;
}

.toggletip {
  appearance: none;
  background: none;
  border: 0;
  color: var(--toggle-tip-color);
  cursor: pointer;
  line-height: 1;
  padding: 0;
}

.bubble {
  --arrow-distance: -4px;

  position: relative;
  z-index: 100;

  &[data-popper-placement^='top'] .arrow {
    bottom: var(--arrow-distance);
  }

  &[data-popper-placement^='bottom'] .arrow {
    top: var(--arrow-distance);
  }

  &[data-popper-placement^='left'] .arrow {
    right: var(--arrow-distance);
  }

  &[data-popper-placement^='right'] .arrow {
    left: var(--arrow-distance);
  }
}

.arrow {
  &,
  &::before {
    --arrow-size: 10px;

    height: var(--arrow-size);
    position: absolute;
    width: var(--arrow-size);
  }

  &::before {
    background-color: var(--toggle-tip-background);
    content: '';
    left: 0;
    top: 0;
    transform: rotate(45deg);
  }
}

.message {
  background-color: var(--toggle-tip-background);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  color: var(--toggle-tip-color);
  display: inline-block;
  font-size: 0.8rem;
  line-height: 1.3;
  padding: 0.5rem 0.75rem;

  span {
    display: block;
  }
}
