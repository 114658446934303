.icon {
  display: block;
  width: 1.25em;
}

.body {
  flex: 1;
  padding: 0 30px 0 10px;
}

.shortcut {
}
