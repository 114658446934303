.noSelectionContainer {
  align-items: center;
  border: 1px dashed rgba(#fff, 0.5);
  color: rgba(#fff, 0.5);
  display: flex;
  font-size: 1.25rem;
  font-style: italic;
  justify-content: center;
  margin: 0 auto;
  width: 75%;

  // @TODO: come up with something better
  height: 160.56px; // a magic number
}

.toolContainer {
  display: flex;
  height: 100%;

  & > section {
    border: 1px solid rgba(#fff, 0.3);
    display: flex;
    margin-right: 0.5rem;
    overflow: hidden;
    padding: 1rem 1.25rem;

    &:last-child {
      margin-right: 0;
    }

    label,
    legend {
      font-size: 0.8rem;
      font-weight: bold;
    }
  }
}
