.position,
.size {
  margin-right: 1.5rem;
}

.rotation {
  label {
    margin-bottom: 0.5rem;
  }
}
