.linkList {
  padding: 0;
}

.linkListItem {
  display: flex;
}

.deletedItem {
  font-style: italic;
  text-decoration: line-through;
}

.deleteButton {
  margin-left: auto;
}

.addLinkContainer {
  display: flex;
  padding-right: 1rem;
  width: 100%;
}

.teleporterSelection {
  flex: 1;
  margin-right: 1rem;
}
