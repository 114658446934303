.stacked {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;

  label {
    margin-bottom: var(--spacing-small);
  }

  select {
    flex-grow: 1;
  }
}
