.wrapper {
  padding: 0;
}

.legend {
  font-size: 0.85rem;
  font-weight: bold;
  width: auto;
}

.numericalControl {
  margin-bottom: 0.25rem;
}
