@import '../../sass/variables';

.wrapper {
}

.legend {
  border-bottom: 1px solid rgba($color-white, 0.25);
  font-size: 1em;
  margin-bottom: 0.75rem;
  padding-bottom: 0.25rem;
}

.body {
}
